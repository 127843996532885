<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Dashboard component
 */
export default {
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Nazox",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
      cpuused: 50,
      cpu_progresscolor: "#20a53a",
      memory: {
        totalmem: 0,
        freemem: 0,
      },
      memory_progresscolor: "#20a53a",
      hostname: "",
      store: {
        total: 0,
        free: 0,
      },
      userinfo: [],
      systeminfo: [],
      diskinfo: [],
    };
  },
  mounted() {
    var that = this;
    that.title = that.$t("system.text");
    that.items = [
      {
        text: that.$t("system.text"),
        href: "/",
      },
    ];
    that.userinfo = JSON.parse(localStorage.getItem("user"));
    that.getsysteminfo();
  },
  methods: {
    handdleMsg(msg) {},
    getcpuused() {
      if (this.cpuused >= 80) {
        this.cpu_progresscolor = "#f7b851";
      } else if (this.cpuused >= 90) {
        this.cpu_progresscolor = "#ff0017";
      } else {
        this.cpu_progresscolor = "#20a53a";
      }
    },
    getsysteminfo() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "systeminfo",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.systeminfo = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  filters: {
    parsemb(val) {
      if (val === 0) {
        return val;
      }
      return val.toFixed(2);
    },
    formatTimer: function (value) {
      value = value * 1000;
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <table class="table">
              <tbody>
                <tr v-for="(val, idx) in systeminfo" :key="idx">
                  <td>{{ $t("system.group." + idx) }}</td>
                  <td v-if="!val">{{ $t("system.group.disable") }}</td>
                  <td v-else-if="idx === 'aftersale'">
                    {{ val | formatTimer }}
                  </td>
                  <td v-else-if="idx === 'model'">
                    <span v-if="val==='0'">
                      {{$t("setting.system.default")}}
                    </span>
                    <span v-else-if="val==='1'">
                      {{$t("setting.system.counter")}}
                    </span>
                    <span v-else-if="val==='2'">
                      {{$t("setting.system.card")}}
                    </span>
                  </td>
                  <td v-else>{{ $t("system.group.enable") }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>